.w3-metro-light-green
{color:#fff!important;background-color:#99b433!important}
.w3-metro-green
{color:#fff!important;background-color:#00a300!important}
.w3-metro-dark-green
{color:#fff!important;background-color:#1e7145!important}
.w3-metro-magenta
{color:#fff!important;background-color:#ff0097!important}
.w3-metro-light-purple
{color:#fff!important;background-color:#9f00a7!important}
.w3-metro-purple
{color:#fff!important;background-color:#7e3878!important}
.w3-metro-dark-purple
{color:#fff!important;background-color:#603cba!important}
.w3-metro-darken
{color:#fff!important;background-color:#1d1d1d!important}
.w3-metro-teal
{color:#fff!important;background-color:#00aba9!important}
.w3-metro-light-blue
{color:#000!important;background-color:#eff4ff!important}
.w3-metro-blue
{color:#fff!important;background-color:#2d89ef!important}
.w3-metro-dark-blue
{color:#fff!important;background-color:#2b5797!important}
.w3-metro-yellow
{color:#fff!important;background-color:#ffc40d!important}
.w3-metro-orange
{color:#fff!important;background-color:#e3a21a!important}
.w3-metro-dark-orange
{color:#fff!important;background-color:#da532c!important}
.w3-metro-red
{color:#fff!important;background-color:#ee1111!important}
.w3-metro-dark-red
{color:#fff!important;background-color:#b91d47!important}

.w3-win8-lime
{color:#fff!important;background-color:#a4c400!important}
.w3-win8-green
{color:#fff!important;background-color:#60a917!important}
.w3-win8-emerald
{color:#fff!important;background-color:#008a00!important}
.w3-win8-teal
{color:#fff!important;background-color:#00aba9!important}
.w3-win8-cyan
{color:#fff!important;background-color:#1ba1e2!important}
.w3-win8-blue
{color:#fff!important;background-color:#3e65ff!important}
.w3-win8-cobalt
{color:#fff!important;background-color:#0050ef!important}
.w3-win8-indigo
{color:#fff!important;background-color:#6a00ff!important}
.w3-win8-violet
{color:#fff!important;background-color:#aa00ff!important}
.w3-win8-pink
{color:#fff!important;background-color:#f472d0!important}
.w3-win8-magenta
{color:#fff!important;background-color:#d80073!important}
.w3-win8-crimson
{color:#fff!important;background-color:#a20025!important}
.w3-win8-red
{color:#fff!important;background-color:#e51400!important}
.w3-win8-orange
{color:#fff!important;background-color:#fa6800!important}
.w3-win8-amber
{color:#fff!important;background-color:#f0a30a!important}
.w3-win8-yellow
{color:#fff!important;background-color:#e3c800!important}
.w3-win8-brown
{color:#fff!important;background-color:#825a2c!important}
.w3-win8-olive
{color:#fff!important;background-color:#6d8764!important}
.w3-win8-steel
{color:#fff!important;background-color:#647687!important}
.w3-win8-mauve
{color:#fff!important;background-color:#76608a!important}
.w3-win8-taupe
{color:#fff!important;background-color:#87794e!important}
.w3-win8-sienna
{color:#fff!important;background-color:#a0522d!important}

.w3-flat-turquoise
{color:#fff!important;background-color:#1abc9c!important}
.w3-flat-emerald
{color:#fff!important;background-color:#2ecc71!important}
.w3-flat-peter-river
{color:#fff!important;background-color:#3498db!important}
.w3-flat-amethyst
{color:#fff!important;background-color:#9b59b6!important}
.w3-flat-wet-asphalt
{color:#fff!important;background-color:#34495e!important}
.w3-flat-green-sea
{color:#fff!important;background-color:#16a085!important}
.w3-flat-nephritis
{color:#fff!important;background-color:#27ae60!important}
.w3-flat-belize-hole
{color:#fff!important;background-color:#2980b9!important}
.w3-flat-wisteria
{color:#fff!important;background-color:#8e44ad!important}
.w3-flat-midnight-blue
{color:#fff!important;background-color:#2c3e50!important}
.w3-flat-sun-flower
{color:#fff!important;background-color:#f1c40f!important}
.w3-flat-carrot
{color:#fff!important;background-color:#e67e22!important}
.w3-flat-alizarin
{color:#fff!important;background-color:#e74c3c!important}
.w3-flat-clouds
{color:#000!important;background-color:#ecf0f1!important}
.w3-flat-concrete
{color:#fff!important;background-color:#95a5a6!important}
.w3-flat-orange
{color:#fff!important;background-color:#f39c12!important}
.w3-flat-pumpkin
{color:#fff!important;background-color:#d35400!important}
.w3-flat-pomegranate
{color:#fff!important;background-color:#c0392b!important}
.w3-flat-silver
{color:#000!important;background-color:#bdc3c7!important}
.w3-flat-asbestos
{color:#fff!important;background-color:#7f8c8d!important}

.w3-highway-brown
{color:#fff!important;background-color:#633517!important}
.w3-highway-red
{color:#fff!important;background-color:#a6001a!important}
.w3-highway-orange
{color:#fff!important;background-color:#e06000!important}
.w3-highway-schoolbus
{color:#fff!important;background-color:#ee9600!important}
.w3-highway-yellow
{color:#fff!important;background-color:#ffab00!important}
.w3-highway-green
{color:#fff!important;background-color:#004d33!important}
.w3-highway-blue
{color:#fff!important;background-color:#00477e!important}

.w3-safety-red
{color:#fff!important;background-color:#bd1e24!important}
.w3-safety-orange
{color:#fff!important;background-color:#e97600!important}
.w3-safety-yellow
{color:#fff!important;background-color:#f6c700!important}
.w3-safety-green
{color:#fff!important;background-color:#007256!important}
.w3-safety-blue
{color:#fff!important;background-color:#0067a7!important}
.w3-safety-purple
{color:#fff!important;background-color:#964f8e!important}

.w3-signal-yellow
{color:#fff!important;background-color:#f7ba0b!important}
.w3-signal-orange
{color:#fff!important;background-color:#d4652f!important}
.w3-signal-red
{color:#fff!important;background-color:#a02128!important}
.w3-signal-violet
{color:#fff!important;background-color:#904684!important}
.w3-signal-blue
{color:#fff!important;background-color:#154889!important}
.w3-signal-green
{color:#fff!important;background-color:#317f43!important}
.w3-signal-grey
{color:#fff!important;background-color:#9b9b9b!important}
.w3-signal-brown
{color:#fff!important;background-color:#7b5141!important}
.w3-signal-white
{color:#000!important;background-color:#f4f4f4!important}
.w3-signal-black
{color:#fff!important;background-color:#282828!important}

.w3-2017-greenery
{color:#fff!important;background-color:#92B558!important}
.w3-2017-grenadine
{color:#fff!important;background-color:#DC4C46!important}
.w3-2017-tawny-port
{color:#fff!important;background-color:#672e3b!important}
.w3-2017-ballet-slipper
{color:#000!important;background-color:#f3d6e4!important}
.w3-2017-butterum
{color:#fff!important;background-color:#c48f65!important}
.w3-2017-navy-peony
{color:#fff!important;background-color:#223a5e!important}
.w3-2017-neutral-gray,.w3-2017-neutral-grey
{color:#fff!important;background-color:#898e8c!important}
.w3-2017-shaded-spruce
{color:#fff!important;background-color:#005960!important}
.w3-2017-golden-lime
{color:#fff!important;background-color:#9c9a40!important}
.w3-2017-marina
{color:#fff!important;background-color:#4F84C4!important}
.w3-2017-autumn-maple
{color:#fff!important;background-color:#d2691e!important}
.w3-2017-niagara
{color:#fff!important;background-color:#578CA9!important}
.w3-2017-primrose-yellow
{color:#000!important;background-color:#f6d155!important}
.w3-2017-lapis-blue
{color:#fff!important;background-color:#004b8d!important}
.w3-2017-flame
{color:#fff!important;background-color:#f2552c!important}
.w3-2017-island-paradise
{color:#000!important;background-color:#95dee3!important}
.w3-2017-pale-dogwood
{color:#000!important;background-color:#edcdc2!important}
.w3-2017-pink-yarrow
{color:#fff!important;background-color:#ce3175!important}
.w3-2017-kale
{color:#fff!important;background-color:#5a7247!important}
.w3-2017-hazelnut
{color:#000!important;background-color:#cfb095!important}

.w3-2018-red-pear
{color:#fff!important;background-color:#7F4145!important}
.w3-2018-valiant-poppy
{color:#fff!important;background-color:#BD3D3A!important}
.w3-2018-nebulas-blue
{color:#fff!important;background-color:#3F69AA!important}
.w3-2018-ceylon-yellow
{color:#000!important;background-color:#D5AE41!important}
.w3-2018-martini-olive
{color:#fff!important;background-color:#766F57!important}
.w3-2018-russet-orange
{color:#fff!important;background-color:#E47A2E!important}
.w3-2018-crocus-petal
{color:#000!important;background-color:#BE9EC9!important}
.w3-2018-limelight
{color:#000!important;background-color:#F1EA7F!important}
.w3-2018-quetzal-green
{color:#fff!important;background-color:#006E6D!important}
.w3-2018-sargasso-sea
{color:#fff!important;background-color:#485167!important}
.w3-2018-tofu
{color:#000!important;background-color:#EAE6DA!important}
.w3-2018-almond-buff
{color:#000!important;background-color:#D1B894!important}
.w3-2018-quiet-gray,.w3-2018-quiet-grey
{color:#000!important;background-color:#BCBCBE!important}
.w3-2018-meerkat
{color:#000!important;background-color:#95dee3!important}
.w3-2018-meadowlark
{color:#000!important;background-color:#ECDB54!important}
.w3-2018-cherry-tomato
{color:#fff!important;background-color:#E94B3C!important}
.w3-2018-little-boy-blue
{color:#000!important;background-color:#6F9FD8!important}
.w3-2018-chili-oil
{color:#fff!important;background-color:#944743!important}
.w3-2018-pink-lavender
{color:#000!important;background-color:#DBB1CD!important}
.w3-2018-blooming-dahlia
{color:#000!important;background-color:#EC9787!important}
.w3-2018-arcadia
{color:#fff!important;background-color:#00A591!important}
.w3-2018-emperador
{color:#fff!important;background-color:#6C4F3D!important}
.w3-2018-ultra-violet
{color:#fff!important;background-color:#6B5B95!important}
.w3-2018-almost-mauve
{color:#000!important;background-color:#EADEDB!important}
.w3-2018-spring-crocus
{color:#fff!important;background-color:#BC70A4!important}
.w3-2018-lime-punch
{color:#000!important;background-color:#BFD641!important}
.w3-2018-sailor-blue
{color:#fff!important;background-color:#2E4A62!important}
.w3-2018-harbor-mist
{color:#000!important;background-color:#B4B7BA!important}
.w3-2018-warm-sand
{color:#000!important;background-color:#C0AB8E!important}
.w3-2018-coconut-milk
{color:#000!important;background-color:#F0EDE5!important}

.w3-vivid-pink
{color:#fff!important;background-color:#ffb5bA!important}
.w3-vivid-red
{color:#fff!important;background-color:#be0032!important}
.w3-vivid-orange
{color:#fff!important;background-color:#f38400!important}
.w3-vivid-yellow
{color:#fff!important;background-color:#f3c300!important}
.w3-vivid-green
{color:#fff!important;background-color:#008856!important}
.w3-vivid-blue
{color:#fff!important;background-color:#00a1c2!important}
.w3-vivid-black
{color:#fff!important;background-color:#000!important}
.w3-vivid-white
{color:#000!important;background-color:#fff!important}
.w3-vivid-purple
{color:#fff!important;background-color:#9a4eae!important}
.w3-vivid-purple
{color:#fff!important;background-color:#9a4eae!important}
.w3-vivid-yellowish-pink
{color:#fff!important;background-color:#ffb7a5!important}
.w3-vivid-reddish-orange
{color:#fff!important;background-color:#e25822!important}
.w3-vivid-orange-yellow
{color:#fff!important;background-color:#f6a600!important}
.w3-vivid-greenish-yellow
{color:#fff!important;background-color:#dcd300!important}
.w3-vivid-yellow-green
{color:#fff!important;background-color:#8db600!important}
.w3-vivid-yellowish-green
{color:#fff!important;background-color:#27a64c!important}
.w3-vivid-bluish-green
{color:#fff!important;background-color:#008882!important}
.w3-vivid-greenish-blue
{color:#fff!important;background-color:#0085a1!important}
.w3-vivid-purplish-blue
{color:#fff!important;background-color:#30267a!important}
.w3-vivid-reddish-purple
{color:#fff!important;background-color:#870074!important}
.w3-vivid-purplish-red
{color:#fff!important;background-color:#ce4676!important}

.w3-food-apple
{color:#000!important;background-color:#76cd26!important}
.w3-food-aspargus
{color:#fff!important;background-color:#77ab56!important}
.w3-food-apricot
{color:#fff!important;background-color:#ffb16d!important}
.w3-food-aubergine
{color:#fff!important;background-color:#3d0734!important}
.w3-food-avocado
{color:#fff!important;background-color:#87a922!important}
.w3-food-banana
{color:#000!important;background-color:#fafe4b!important}
.w3-food-butter
{color:#000!important;background-color:#ffff81!important}
.w3-food-blueberry
{color:#fff!important;background-color:#464196!important}
.w3-food-carrot
{color:#fff!important;background-color:#e67e22!important}
.w3-food-cherry
{color:#fff!important;background-color:#cf0234!important}
.w3-food-chocolate
{color:#fff!important;background-color:#411900!important}
.w3-food-cranberry
{color:#fff!important;background-color:#9e003a!important}
.w3-food-coffee
{color:#fff!important;background-color:#a6814c!important}
.w3-food-egg
{color:#000!important;background-color:#fffcc4!important}
.w3-food-grape
{color:#fff!important;background-color:#6c3461!important}
.w3-food-kiwi
{color:#000!important;background-color:#8ee53f!important}
.w3-food-lemon
{color:#000!important;background-color:#fdff52!important}
.w3-food-lime
{color:#000!important;background-color:#bffe28!important}
.w3-food-mango
{color:#fff!important;background-color:#ffa62b!important}
.w3-food-mushroom
{color:#fff!important;background-color:#ba9e88!important}
.w3-food-mustard
{color:#fff!important;background-color:#ceb301!important}
.w3-food-mint
{color:#000!important;background-color:#c0fa8b!important}
.w3-food-olive
{color:#fff!important;background-color:#6e750e!important}
.w3-food-orange
{color:#fff!important;background-color:#f97306!important}
.w3-food-pea
{color:#fff!important;background-color:#a4bf20!important}
.w3-food-peach
{color:#fff!important;background-color:#ffb07c!important}
.w3-food-pear
{color:#000!important;background-color:#cbf85f!important}
.w3-food-pistachio
{color:#000!important;background-color:#c0fa8b!important}
.w3-food-plum
{color:#fff!important;background-color:#3f012c!important}
.w3-food-pomegranate
{color:#fff!important;background-color:#c0392b!important}
.w3-food-pumpkin
{color:#fff!important;background-color:#d35400!important}
.w3-food-raspberry
{color:#fff!important;background-color:#b00149!important}
.w3-food-saffron
{color:#fff!important;background-color:#feb209!important}
.w3-food-salmon
{color:#fff!important;background-color:#ff796c!important}
.w3-food-spearmint
{color:#000!important;background-color:#1ef876!important}
.w3-food-squash
{color:#fff!important;background-color:#f2ab15!important}
.w3-food-strawberry
{color:#fff!important;background-color:#fb2943!important}
.w3-food-tomato
{color:#fff!important;background-color:#ec2d01!important}
.w3-food-wheat
{color:#000!important;background-color:#fbdd7e!important}
.w3-food-wine
{color:#fff!important;background-color:#80013f!important}

.w3-2019-fiesta
{color:#fff!important;background-color:#DD4132!important}
.w3-2019-jester-red
{color:#fff!important;background-color:#9E1030!important}
.w3-2019-turmeric
{color:#fff!important;background-color:#FE840E!important}
.w3-2019-living-coral
{color:#fff!important;background-color:#FF6F61!important}
.w3-2019-pink-peacock
{color:#fff!important;background-color:#C62168!important}
.w3-2019-pepper-stem
{color:#fff!important;background-color:#8D9440!important}
.w3-2019-aspen-gold
{color:#000!important;background-color:#FFD662!important}
.w3-2019-princess-blue
{color:#fff!important;background-color:#00539C!important}
.w3-2019-toffee
{color:#fff!important;background-color:#755139!important}
.w3-2019-mango-mojito
{color:#000!important;background-color:#D69C2F!important}
.w3-2019-terrarium-moss
{color:#fff!important;background-color:#616247!important}
.w3-2019-sweet-lilac
{color:#000!important;background-color:#E8B5CE!important}
.w3-2019-soybean
{color:#000!important;background-color:#D2C29D!important}
.w3-2019-eclipse
{color:#fff!important;background-color:#343148!important}
.w3-2019-sweet-corn
{color:#000!important;background-color:#F0EAD6!important}
.w3-2019-brown-granite
{color:#fff!important;background-color:#615550!important}
.w3-2019-chili-pepper
{color:#fff!important;background-color:#9B1B30!important}
.w3-2019-biking-red
{color:#fff!important;background-color:#77212E!important}
.w3-2019-creme-de-peche
{color:#000!important;background-color:#F5D6C6!important}
.w3-2019-peach-pink
{color:#fff!important;background-color:#FA9A85!important}
.w3-2019-rocky-road
{color:#fff!important;background-color:#5A3E36!important}
.w3-2019-fruit-dove
{color:#fff!important;background-color:#CE5B78!important}
.w3-2019-sugar-almond
{color:#fff!important;background-color:#935529!important}
.w3-2019-dark-cheddar
{color:#fff!important;background-color:#E08119!important}
.w3-2019-galaxy-blue
{color:#fff!important;background-color:#2A4B7C!important}
.w3-2019-bluestone
{color:#fff!important;background-color:#577284!important}
.w3-2019-orange-tiger
{color:#fff!important;background-color:#F96714!important}
.w3-2019-eden
{color:#fff!important;background-color:#264E36!important}
.w3-2019-vanilla-custard
{color:#000!important;background-color:#F3E0BE!important}
.w3-2019-evening-blue
{color:#fff!important;background-color:#2A293E!important}
.w3-2019-paloma
{color:#fff!important;background-color:#9F9C99!important}
.w3-2019-guacamole
{color:#fff!important;background-color:#797B3A!important}

.w3-2019-fiesta
{color:#fff!important;background-color:#DD4132!important}
.w3-2019-jester-red
{color:#fff!important;background-color:#9E1030!important}
.w3-2019-turmeric
{color:#fff!important;background-color:#FE840E!important}
.w3-2019-living-coral
{color:#fff!important;background-color:#FF6F61!important}
.w3-2019-pink-peacock
{color:#fff!important;background-color:#C62168!important}
.w3-2019-pepper-stem
{color:#fff!important;background-color:#8D9440!important}
.w3-2019-aspen-gold
{color:#000!important;background-color:#FFD662!important}
.w3-2019-princess-blue
{color:#fff!important;background-color:#00539C!important}
.w3-2019-toffee
{color:#fff!important;background-color:#755139!important}
.w3-2019-mango-mojito
{color:#000!important;background-color:#D69C2F!important}
.w3-2019-terrarium-moss
{color:#fff!important;background-color:#616247!important}
.w3-2019-sweet-lilac
{color:#000!important;background-color:#E8B5CE!important}
.w3-2019-soybean
{color:#000!important;background-color:#D2C29D!important}
.w3-2019-eclipse
{color:#fff!important;background-color:#343148!important}
.w3-2019-sweet-corn
{color:#000!important;background-color:#F0EAD6!important}
.w3-2019-brown-granite
{color:#fff!important;background-color:#615550!important}
.w3-2019-chili-pepper
{color:#fff!important;background-color:#9B1B30!important}
.w3-2019-biking-red
{color:#fff!important;background-color:#77212E!important}
.w3-2019-creme-de-peche
{color:#000!important;background-color:#F5D6C6!important}
.w3-2019-peach-pink
{color:#fff!important;background-color:#FA9A85!important}
.w3-2019-rocky-road
{color:#fff!important;background-color:#5A3E36!important}
.w3-2019-fruit-dove
{color:#fff!important;background-color:#CE5B78!important}
.w3-2019-sugar-almond
{color:#fff!important;background-color:#935529!important}
.w3-2019-dark-cheddar
{color:#fff!important;background-color:#E08119!important}
.w3-2019-galaxy-blue
{color:#fff!important;background-color:#2A4B7C!important}
.w3-2019-bluestone
{color:#fff!important;background-color:#577284!important}
.w3-2019-orange-tiger
{color:#fff!important;background-color:#F96714!important}
.w3-2019-eden
{color:#fff!important;background-color:#264E36!important}
.w3-2019-vanilla-custard
{color:#000!important;background-color:#F3E0BE!important}
.w3-2019-evening-blue
{color:#fff!important;background-color:#2A293E!important}
.w3-2019-paloma
{color:#fff!important;background-color:#9F9C99!important}
.w3-2019-guacamole
{color:#fff!important;background-color:#797B3A!important}

.w3-2020-classic-blue
{color:#fff;background-color:#34568B}
.w3-2020-flame-scarlet
{color:#fff;background-color:#CD212A}
.w3-2020-saffron
{color:#000;background-color:#FFA500}
.w3-2020-biscay-green
{color:#000;background-color:#56C6A9}
.w3-2020-chive
{color:#fff;background-color:#4B5335}
.w3-2020-faded-denim
{color:#fff;background-color:#798EA4}
.w3-2020-orange-peel
{color:#fff;background-color:#FA7A35}
.w3-2020-mosaic-blue
{color:#fff;background-color:#00758F}
.w3-2020-sunlight
{color:#000;background-color:#EDD59E}
.w3-2020-coral-pink
{color:#000;background-color:#E8A798}
.w3-2020-cinnamon-stick
{color:#fff;background-color:#9C4722}
.w3-2020-grape-compote
{color:#fff;background-color:#6B5876}
.w3-2020-lark
{color:#fff;background-color:#B89B72}
.w3-2020-navy-blazer
{color:#fff;background-color:#282D3C}
.w3-2020-brilliant-white
{color:#000;background-color:#EDF1FF}
.w3-2020-ash
{color:#fff;background-color:#A09998}
.w3-2020-amberglow
{color:#fff;background-color:#DC793E}
.w3-2020-samba
{color:#fff;background-color:#A2242F}
.w3-2020-sandstone
{color:#fff;background-color:#C48A69}
.w3-2020-green-sheen
{color:#000;background-color:#D9CE52}
.w3-2020-rose-tan
{color:#000;background-color:#D19C97}
.w3-2020-ultramarine-green
{color:#fff;background-color:#006B54}
.w3-2020-fired-brick
{color:#fff;background-color:#6A2E2A}
.w3-2020-peach-nougat
{color:#000;background-color:#E6AF91}
.w3-2020-magenta-purple
{color:#fff;background-color:#6C244C}

.w3-2021-marigold
{color:#fff;background-color:#FDAC53}
.w3-2021-cerulean
{color:#000;background-color:#9BB7D4}
.w3-2021-rust
{color:#fff;background-color:#B55A30}
.w3-2021-illuminating
{color:#000;background-color:#F5DF4D}
.w3-2021-french-blue
{color:#fff;background-color:#0072B5}
.w3-2021-green-ash
{color:#000;background-color:#A0DAA9}
.w3-2021-burnt-coral
{color:#fff;background-color:#E9897E}
.w3-2021-mint
{color:#fff;background-color:#00A170}
.w3-2021-amethyst-orchid
{color:#fff;background-color:#926AA6}
.w3-2021-raspberry-sorbet
{color:#fff;background-color:#D2386C}
.w3-2021-inkwell
{color:#fff;background-color:#363945}
.w3-2021-ultimate-gray
{color:#fff;background-color:#939597}
.w3-2021-buttercream
{color:#000;background-color:#EFE1CE}
.w3-2021-desert-mist
{color:#000;background-color:#E0B589}
.w3-2021-willow
{color:#fff;background-color:#9A8B4F}


.w3-amber,.w3-hover-amber:hover{color:#000!important;background-color:#ffc107!important}
.w3-aqua,.w3-hover-aqua:hover{color:#000!important;background-color:#00ffff!important}
.w3-blue,.w3-hover-blue:hover{color:#fff!important;background-color:#2196F3!important}
.w3-light-blue,.w3-hover-light-blue:hover{color:#000!important;background-color:#87CEEB!important}
.w3-brown,.w3-hover-brown:hover{color:#fff!important;background-color:#795548!important}
.w3-cyan,.w3-hover-cyan:hover{color:#000!important;background-color:#00bcd4!important}
.w3-blue-grey,.w3-hover-blue-grey:hover,.w3-blue-gray,.w3-hover-blue-gray:hover{color:#fff!important;background-color:#607d8b!important}
.w3-green,.w3-hover-green:hover{color:#fff!important;background-color:#4CAF50!important}
.w3-light-green,.w3-hover-light-green:hover{color:#000!important;background-color:#8bc34a!important}
.w3-indigo,.w3-hover-indigo:hover{color:#fff!important;background-color:#3f51b5!important}
.w3-khaki,.w3-hover-khaki:hover{color:#000!important;background-color:#f0e68c!important}
.w3-lime,.w3-hover-lime:hover{color:#000!important;background-color:#cddc39!important}
.w3-orange,.w3-hover-orange:hover{color:#000!important;background-color:#ff9800!important}
.w3-deep-orange,.w3-hover-deep-orange:hover{color:#fff!important;background-color:#ff5722!important}
.w3-pink,.w3-hover-pink:hover{color:#fff!important;background-color:#e91e63!important}
.w3-purple,.w3-hover-purple:hover{color:#fff!important;background-color:#9c27b0!important}
.w3-deep-purple,.w3-hover-deep-purple:hover{color:#fff!important;background-color:#673ab7!important}
.w3-red,.w3-hover-red:hover{color:#fff!important;background-color:#f44336!important}
.w3-sand,.w3-hover-sand:hover{color:#000!important;background-color:#fdf5e6!important}
.w3-teal,.w3-hover-teal:hover{color:#fff!important;background-color:#009688!important}
.w3-yellow,.w3-hover-yellow:hover{color:#000!important;background-color:#ffeb3b!important}
.w3-white,.w3-hover-white:hover{color:#000!important;background-color:#fff!important}
.w3-black,.w3-hover-black:hover{color:#fff!important;background-color:#000!important}
.w3-grey,.w3-hover-grey:hover,.w3-gray,.w3-hover-gray:hover{color:#000!important;background-color:#9e9e9e!important}
.w3-light-grey,.w3-hover-light-grey:hover,.w3-light-gray,.w3-hover-light-gray:hover{color:#000!important;background-color:#f1f1f1!important}
.w3-dark-grey,.w3-hover-dark-grey:hover,.w3-dark-gray,.w3-hover-dark-gray:hover{color:#fff!important;background-color:#616161!important}
.w3-pale-red,.w3-hover-pale-red:hover{color:#000!important;background-color:#ffdddd!important}
.w3-pale-green,.w3-hover-pale-green:hover{color:#000!important;background-color:#ddffdd!important}
.w3-pale-yellow,.w3-hover-pale-yellow:hover{color:#000!important;background-color:#ffffcc!important}
.w3-pale-blue,.w3-hover-pale-blue:hover{color:#000!important;background-color:#ddffff!important}
.w3-text-amber,.w3-hover-text-amber:hover{color:#ffc107!important}
.w3-text-aqua,.w3-hover-text-aqua:hover{color:#00ffff!important}
.w3-text-blue,.w3-hover-text-blue:hover{color:#2196F3!important}
.w3-text-light-blue,.w3-hover-text-light-blue:hover{color:#87CEEB!important}
.w3-text-brown,.w3-hover-text-brown:hover{color:#795548!important}
.w3-text-cyan,.w3-hover-text-cyan:hover{color:#00bcd4!important}
.w3-text-blue-grey,.w3-hover-text-blue-grey:hover,.w3-text-blue-gray,.w3-hover-text-blue-gray:hover{color:#607d8b!important}
.w3-text-green,.w3-hover-text-green:hover{color:#4CAF50!important}
.w3-text-light-green,.w3-hover-text-light-green:hover{color:#8bc34a!important}
.w3-text-indigo,.w3-hover-text-indigo:hover{color:#3f51b5!important}
.w3-text-khaki,.w3-hover-text-khaki:hover{color:#b4aa50!important}
.w3-text-lime,.w3-hover-text-lime:hover{color:#cddc39!important}
.w3-text-orange,.w3-hover-text-orange:hover{color:#ff9800!important}
.w3-text-deep-orange,.w3-hover-text-deep-orange:hover{color:#ff5722!important}
.w3-text-pink,.w3-hover-text-pink:hover{color:#e91e63!important}
.w3-text-purple,.w3-hover-text-purple:hover{color:#9c27b0!important}
.w3-text-deep-purple,.w3-hover-text-deep-purple:hover{color:#673ab7!important}
.w3-text-red,.w3-hover-text-red:hover{color:#f44336!important}
.w3-text-sand,.w3-hover-text-sand:hover{color:#fdf5e6!important}
.w3-text-teal,.w3-hover-text-teal:hover{color:#009688!important}
.w3-text-yellow,.w3-hover-text-yellow:hover{color:#d2be0e!important}
.w3-text-white,.w3-hover-text-white:hover{color:#fff!important}
.w3-text-black,.w3-hover-text-black:hover{color:#000!important}
.w3-text-grey,.w3-hover-text-grey:hover,.w3-text-gray,.w3-hover-text-gray:hover{color:#757575!important}
.w3-text-light-grey,.w3-hover-text-light-grey:hover,.w3-text-light-gray,.w3-hover-text-light-gray:hover{color:#f1f1f1!important}
.w3-text-dark-grey,.w3-hover-text-dark-grey:hover,.w3-text-dark-gray,.w3-hover-text-dark-gray:hover{color:#3a3a3a!important}
.w3-border-amber,.w3-hover-border-amber:hover{border-color:#ffc107!important}
.w3-border-aqua,.w3-hover-border-aqua:hover{border-color:#00ffff!important}
.w3-border-blue,.w3-hover-border-blue:hover{border-color:#2196F3!important}
.w3-border-light-blue,.w3-hover-border-light-blue:hover{border-color:#87CEEB!important}
.w3-border-brown,.w3-hover-border-brown:hover{border-color:#795548!important}
.w3-border-cyan,.w3-hover-border-cyan:hover{border-color:#00bcd4!important}
.w3-border-blue-grey,.w3-hover-border-blue-grey:hover,.w3-border-blue-gray,.w3-hover-border-blue-gray:hover{border-color:#607d8b!important}
.w3-border-green,.w3-hover-border-green:hover{border-color:#4CAF50!important}
.w3-border-light-green,.w3-hover-border-light-green:hover{border-color:#8bc34a!important}
.w3-border-indigo,.w3-hover-border-indigo:hover{border-color:#3f51b5!important}
.w3-border-khaki,.w3-hover-border-khaki:hover{border-color:#f0e68c!important}
.w3-border-lime,.w3-hover-border-lime:hover{border-color:#cddc39!important}
.w3-border-orange,.w3-hover-border-orange:hover{border-color:#ff9800!important}
.w3-border-deep-orange,.w3-hover-border-deep-orange:hover{border-color:#ff5722!important}
.w3-border-pink,.w3-hover-border-pink:hover{border-color:#e91e63!important}
.w3-border-purple,.w3-hover-border-purple:hover{border-color:#9c27b0!important}
.w3-border-deep-purple,.w3-hover-border-deep-purple:hover{border-color:#673ab7!important}
.w3-border-red,.w3-hover-border-red:hover{border-color:#f44336!important}
.w3-border-sand,.w3-hover-border-sand:hover{border-color:#fdf5e6!important}
.w3-border-teal,.w3-hover-border-teal:hover{border-color:#009688!important}
.w3-border-yellow,.w3-hover-border-yellow:hover{border-color:#ffeb3b!important}
.w3-border-white,.w3-hover-border-white:hover{border-color:#fff!important}
.w3-border-black,.w3-hover-border-black:hover{border-color:#000!important}
.w3-border-grey,.w3-hover-border-grey:hover,.w3-border-gray,.w3-hover-border-gray:hover{border-color:#9e9e9e!important}
.w3-border-light-grey,.w3-hover-border-light-grey:hover,.w3-border-light-gray,.w3-hover-border-light-gray:hover{border-color:#f1f1f1!important}
.w3-border-dark-grey,.w3-hover-border-dark-grey:hover,.w3-border-dark-gray,.w3-hover-border-dark-gray:hover{border-color:#616161!important}
.w3-border-pale-red,.w3-hover-border-pale-red:hover{border-color:#ffe7e7!important}.w3-border-pale-green,.w3-hover-border-pale-green:hover{border-color:#e7ffe7!important}
.w3-border-pale-yellow,.w3-hover-border-pale-yellow:hover{border-color:#ffffcc!important}.w3-border-pale-blue,.w3-hover-border-pale-blue:hover{border-color:#e7ffff!important}